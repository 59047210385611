import { type KeyEvents } from 'types/key_event';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const getKeyEvents = async (analyticId: string): Promise<KeyEvents> => {
  const requestPath = `/api/v1/analytics/${analyticId}/key_events`;

  const response = await getRequest(requestPath);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as KeyEvents;
};
